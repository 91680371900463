<template>
  <b-aspect
    id="cropper-container"
    :aspect="roundCropArea ? '1:1' : '16:9'"
  >
    <VueCropper
      :class="{ 'round-cropp-area': roundCropArea }"
      ref="cropper"
      :img="img"
      :output-size="cropperOptions.size"
      :output-type="cropperOptions.outputType"
      :info="false"
      :full="cropperOptions.full"
      :can-move-box="cropperOptions.canMoveBox"
      :can-move="cropperOptions.canMove"
      :fixed-box="cropperOptions.fixedBox"
      :can-scale="cropperOptions.canScale"
      :fixed="cropperOptions.fixed"
      :fixed-number="roundCropArea ? [1, 1]: [16, 9]"
      :enlarge="cropperOptions.enlarge"
      :original="cropperOptions.original"
      :auto-crop="cropperOptions.autoCrop"
      :auto-crop-width="cropperOptions.autoCropWidth"
      :auto-crop-height="cropperOptions.autoCropHeight"
      :center-box="cropperOptions.centerBox"
      :high="cropperOptions.high"
      :info-true="cropperOptions.infoTrue"
      :mode="cropperOptions.mode"
      @realTime="returnImage"
    />
  </b-aspect>
</template>

<script>
import { VueCropper } from 'vue-cropper'

import { BAspect } from 'bootstrap-vue'

export default {
  components: {
    VueCropper,
    BAspect,
  },
  props: {
    img: {
      type: String,
      default: '',
    },
    roundCropArea: {
      type: Boolean,
      default: false,
    },
    returnBlob: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      cropperOptions: {
        img: null,
        size: 1,
        outputType: 'png',
        canMoveBox: true,
        canMove: true,
        canScale: true,
        fixedBox: false,
        fixed: true,
        enlarge: 2,
        full: true,
        original: false,
        autoCrop: true,
        autoCropWidth: '1000',
        autoCropHeight: '1000',
        centerBox: false,
        high: false,
        infoTrue: false,
        mode: 'cover',
      },
    }
  },
  methods: {
    returnImage() {
      if (this.returnBlob) {
        const imageData = {
          base64: null,
          blob: null,
        }
        this.$refs.cropper.getCropBlob(blob => {
          imageData.blob = blob
          this.$refs.cropper.getCropData(base64 => {
            imageData.base64 = base64
            this.$emit('returnImage', imageData)
          })
        })
      } else {
        this.$refs.cropper.getCropData(data => {
          this.$emit('returnImage', data)
        })
      }
    },
  },
}
</script>
