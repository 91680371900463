<template>
  <div>
    <h3 class="font-weight-bolder mb-1">
      {{ $t('Host name') }}
    </h3>
    <div class="w-100 px-1 py-2 create-form-body">
      <b-tabs
        v-model="tab"
        align="left"
      >
        <b-tab :title="$t('Webinar')">
          <create-form method="update" />
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>
<script>
import {
  BTab,
  BTabs,
} from 'bootstrap-vue'

import CreateForm from '@/components/course/CreateForm.vue'

export default {
  components: {
    BTab,
    BTabs,
    CreateForm,
  },
  data() {
    return {
      tab: 0,
    }
  },
}
</script>
