<template>
  <div class="d-lg-flex justify-content-between">
    <div class="col-lg-8 px-0">
      <validation-observer ref="courseRules">
        <b-form-group
          :label="$t('Event name / title')"
          label-for="eventName"
        >
          <validation-provider
            #default="{ errors }"
            :name="$t('Event name / title')"
            rules="required|max:80"
          >
            <b-form-input
              id="eventName"
              v-model="course.title"
              class="bg-light"
              :placeholder="$t('Place some text there...')"
              :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          :label="$t('What is your event about?')"
          label-for="tinymce-editor"
        >
          <validation-provider
            #default="{ errors }"
            :name="$t('Description')"
            rules="required"
          >
            <TinyMCE
              id="tinymce-editor"
              v-model="course.description"
              :placeholder="$t('Place some text there...')"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <div class="row">
          <div class="col-md-4 pl-1">
            <b-form-group
              :label="$t('Start date')"
              label-for="startDate"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('Start date')"
                rules="required"
              >
                <b-form-datepicker
                  id="startDate"
                  v-model="course.start_date"
                  nav-button-variant="muted"
                  class="bg-light"
                  placeholder="DD/MM/YYYY"
                  :locale="$i18n.locale === 'en' ? 'en-UK' : 'nb-NO'"
                  :state="errors.length > 0 ? false : null"
                  :date-format-options="{
                    year: 'numeric',
                    day: 'numeric',
                    month: 'numeric',
                  }"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
          <div class="col-md-4 px-md-1 pl-1">

            <b-form-group
              :label="$t('Start time')"
              label-for="startTime"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('Start time')"
                rules="required"
              >
                <b-input-group class=" w-100">
                  <cleave
                    id="time-input"
                    v-model="course.start_time"
                    class="form-control bg-light"
                    :raw="false"
                    :options="options.time"
                    placeholder="HH:MM:SS"
                    :state="errors.length > 0 ? false : null"
                  />
                  <b-input-group-append>
                    <b-form-timepicker
                      v-model="course.start_time"
                      class="bg-light form-control"
                      hide-header
                      button-only
                      no-close-button
                      show-seconds
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
          <div class="col-md-4 px-md-1 pl-1">
            <b-form-group
              :label="$t('Estimated duration')"
              label-for="estimatedDuration"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('Estimated duration')"
                rules="required"
              >
                <v-select
                  v-model="course.estimated_duration"
                  class="bg-light"
                  :placeholder="$t('Select duration')"
                  label="text"
                  :reduce="(duration) => duration.value"
                  :options="estimated_durations"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4 pl-1">
            <b-form-group
              :label="$t('Target')"
              label-for="estimatedDuration"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('Target')"
                rules="required"
              >
                <v-select
                  v-model="course.target_specialties"
                  class="bg-light"
                  label="name"
                  :reduce="(specialty) => specialty.id"
                  :placeholder="$t('Select specialities')"
                  multiple
                  :state="errors.length > 0 ? false : null"
                  :options="target_specialties"
                  @input="SelectAll"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
          <div class="col-md-4 px-md-1 pl-1">
            <b-form-group
              :label="$t('Subscription deadline')"
              label-for="subscriptionDeadline"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('Subscription deadline')"
                rules="required"
              >
                <b-form-datepicker
                  id="subscriptionDeadline"
                  v-model="course.subscription_deadline"
                  nav-button-variant="muted"
                  class="bg-light"
                  placeholder="DD/MM/YYYY"
                  :locale="$i18n.locale === 'en' ? 'en-UK' : 'nb-NO'"
                  :state="errors.length > 0 ? false : null"
                  :date-format-options="{
                    year: 'numeric',
                    day: 'numeric',
                    month: 'numeric',
                  }"
                />
              </validation-provider>
            </b-form-group>
          </div>
          <!-- <div
            v-if="is_company_course"
            class="col-md-4 px-md-1 pl-1"
          >
            <b-form-group
              :label="$t('Workplace')"
              label-for="workplace"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('Workplace')"
                rules="required"
              >
                <v-select
                  v-model="course.partner_company_id"
                  class="bg-light"
                  label="name"
                  :reduce="(workplace) => workplace.id.toString()"
                  :placeholder="$t('Select workplace')"
                  :state="errors.length > 0 ? false : null"
                  :options="workplaces"
                >
                  <template #list-header>
                    <li class="text-center p-1">
                      <b-button
                        variant="outline-primary"
                        @click="createWorkplace"
                      >
                        {{ $t('Add new workplace') }}
                      </b-button>
                    </li>
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div> -->
        </div>
        <!-- <b-form-checkbox
          v-model="is_company_course"
          class=" mb-2"
        >
          {{ $t('Post on behalf of a company') }}
        </b-form-checkbox> -->
        <h5
          class="font-weight-bolder"
        >
          {{ $t("Thumbnail") }}
        </h5>
        <div class="d-flex flex-wrap mb-1">
          <div
            class="
                file-input
                d-flex
                flex-column
                col-12
                col-md-4
                align-items-center
                justify-content-center
              "
          >
            <img
              src="@/assets/images/pages/marketplace/file-drop-image.svg"
              alt="file"
            >
            <h6 class="font-weight-bolder text-center px-1 pt-1">
              {{ $t("Drop an image here or") }}
              <span class="text-primary cursor-pointer">{{
                $t("click")
              }}</span>
              {{ $t("to upload") }}
            </h6>
            <validation-provider
              #default="{ errors }"
              :name="$t('Images')"
              :rules="$route.params.id ? '' : 'required'"
            >
              <b-form-file
                id="files"
                v-model="image"
                class="drop-input"
                :state="errors.length > 0 ? false : null"
                @input="uploadImg"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </div>

        </div>
        <div
          v-if="cropperImg"
          class="col-12 px-0"
        >
          <cropper
            :img="cropperImg"
            @returnImage="saveCourseImage"
          />

        </div>
        <div
          v-if="course.image"
          class="col-3 px-0 py-1"
        >
          <img
            :src="course.image"
            alt=""
            class="w-100"
          >
        </div>
        <h5
          v-if="mode === 'webcast'"
          class="font-weight-bolder"
        >
          {{ $t("Link to video") }}
        </h5>
        <b-form-group
          v-if="mode === 'webcast'"
          :label="
            $t(
              'Video must be uploaded at least 2 days before the webinar starts. (Dropbox, WeTransfer, Google Drive, etc.)'
            )
          "
          label-for="videoLink"
        >
          <validation-provider
            #default="{ errors }"
            :name="$t('Video')"
            rules="required"
          >
            <b-form-input
              id="videoLink"
              v-model="course.embedded"
              class="bg-light"
              :placeholder="$t('Place some link there…')"
              :state="errors.length > 0 ? false : null"
            />
          </validation-provider>
        </b-form-group>
        <b-form-group
          v-else
          :label="$t('Webinar embed code')"
          label-for="webinarEmbedCode"
        >
          <b-form-textarea
            id="webinarEmbedCode"
            class="bg-light"
            :placeholder="$t('Place some link there…')"
            rows="3"
          />
        </b-form-group>
        <b-form-checkbox
          v-if="method !== 'update' && isABussinesProfile"
          v-model="course.generate_post"
          class="mt-1"
        >
          {{ $t('Generate the post to the feed') }}
        </b-form-checkbox>
      </validation-observer>
    </div>
    <div class="col-lg-4 text-right nopadding">
      <b-button
        class="mr-1"
        variant="primary"
        @click="course_methods[method]()"
      >
        {{ method === "create" ? $t("Publish") : $t("Save changes") }}
      </b-button>
      <b-button
        variant="outline-primary"
        @click="closeCreationForm"
      >
        {{ method === "create" ? $t("Cancel") : $t("Cancel course") }}
      </b-button>
    </div>
  </div>
</template>
<script>
import {
  BFormInput,
  BFormGroup,
  BFormDatepicker,
  BFormTimepicker,
  BFormTextarea,
  BButton,
  BFormCheckbox,
  BInputGroupAppend,
  BInputGroup,
  BFormFile,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, url, digits, regex, max,
} from '@validations'
import Cleave from 'vue-cleave-component'

import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import TinyMCE from '@/components/editor/TinyMCE.vue'
import Cropper from '@/components/cropper/Cropper.vue'

export default {
  components: {
    BFormInput,
    BFormGroup,
    BFormDatepicker,
    BFormTimepicker,
    BFormTextarea,
    BButton,
    BFormCheckbox,
    BInputGroupAppend,
    BInputGroup,
    BFormFile,
    vSelect,
    Cleave,
    TinyMCE,
    ValidationProvider,
    ValidationObserver,
    Cropper,
  },
  props: {
    mode: {
      type: String,
      default: 'webcast',
    },
    method: {
      type: String,
      default: 'create',
    },
  },
  data() {
    return {
      required,
      url,
      max,
      digits,
      regex,
      options: {
        time: {
          time: true,
          datePattern: ['h', 'm', 's'],
          delimiter: ':',
        },
      },
      image: null,
      is_company_course: false,
      course: {
        title: null,
        description: null,
        start_date: null,
        start_time: null,
        estimated_duration: null,
        target_specialties: null,
        subscription_deadline: null,
        embedded: null,
        type: 'webcast',
        published: true,
        partner_company_id: null,
        image: null,
        generate_post: false,
      },
      course_methods: {
        create: this.createCourse,
        update: this.updateCourse,
      },
      estimated_durations: [
        {
          text: this.$t('30 minutes'),
          value: 30,
        },
        {
          text: this.$t('1 hour'),
          value: 60,
        },
        {
          text: this.$t('1 hour 30 minutes'),
          value: 90,
        },
        {
          text: this.$t('2 hours'),
          value: 120,
        },
      ],
      cropperImg: null,
    }
  },
  computed: {
    isABussinesProfile() {
      return this.$store.getters['auth/user'].active_business_profile.id
    },
    target_specialties() {
      return [
        {
          name: 'Select all',
          id: 'select-all',
        },
        ...this.translate(
          this.$store.getters['specialties/specialties'],
          this.$i18n.locale,
        ),
      ]
    },
    workplaces() {
      return this.translate(
        this.$store.getters['workplaces/workplaces'],
        this.$i18n.locale,
      )
    },
  },
  created() {
    this.$store.dispatch('specialties/fetchSpecialties')
    this.$store.dispatch('workplaces/fetchWorkplaces')
    if (this.method === 'update') {
      this.$store
        .dispatch('myCourse/fetchCourseById', this.$route.params.id)
        .then(() => {
          this.course = this.$store.getters['myCourse/selectedCourse']
          this.course.target_specialties = this.course.specialities.map(
            el => el.id,
          )
          if (this.course.partner_company) {
            // eslint-disable-next-line
            this.course.partner_company_id = this.course.partner_company.id.toString()
            this.is_company_course = true
          }
          if (this.course.image) {
            this.cropperImg = this.course.image
          }
        })
    }
  },
  methods: {
    uploadImg(file) {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onloadend = () => {
        this.cropperImg = reader.result
      }
    },
    SelectAll(selectedValues) {
      if (selectedValues.some(el => el === 'select-all')) {
        this.course.target_specialties = this.target_specialties.map(
          el => el.id,
        )
        this.course.target_specialties.splice(0, 1)
      }
    },
    createWorkplace() {
      EventBus.$emit('showCreateWorkplaceModal')
    },
    createCourse() {
      this.$refs.courseRules.validate().then(success => {
        if (success) {
          this.course.start_time = this.course.start_time.substring(0, 5)
          this.$store
            .dispatch('myCourse/createCourse', this.course)
            .then(response => {
              if (response.status === 200) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: this.$t(
                      'Your course has been successfully added! After moderation, it will be published on our resource.',
                    ),
                    icon: 'CheckCircleIcon',
                    variant: 'success',
                  },
                })
                this.$router.go(-1)
              }
            })
        }
      })
    },
    saveCourseImage(val) {
      this.course.image = val
    },
    updateCourse() {
      this.$refs.courseRules.validate().then(success => {
        if (success) {
          this.course.start_time = this.course.start_time.substring(0, 5)
          this.$store
            .dispatch('myCourse/updateCourse', {
              course: this.course,
              id: this.$route.params.id,
            })
            .then(response => {
              if (response.status === 200) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: this.$t(
                      'Your course has been successfully updated! After moderation, it will be published on our resource.',
                    ),
                    icon: 'CheckCircleIcon',
                    variant: 'success',
                  },
                })
                this.$router.go(-1)
              }
            })
        }
      })
    },
    closeCreationForm() {
      this.$router.go(-1)
    },
  },
}
</script>
