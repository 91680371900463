<template>
  <tinymce
    :id="id"
    :ref="id"
    v-model="content"
    :other_options="getOptions"
    @editorInit="initCallBack"
  />
</template>

<script>
import tinymce from 'vue-tinymce-editor'

export default {
  name: 'TinyMCE',
  components: {
    tinymce,
  },
  props: {
    id: {
      required: false,
      type: String,
      default: 'tinymce',
    },
    value: {
      required: true,
      default: '',
    },
    menubar: {
      type: Boolean,
      default: () => true,
    },
    toolbar1: {
      type: Boolean,
      default: () => true,
    },
    toolbar2: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      content: this.value,
      options: {
        menubar: this.menubar,
        placeholder: 'Type here...',
        automatic_uploads: false,
        images_upload_handler: (blobInfo, success, failure) => {
          const formData = new FormData()
          formData.append('image', blobInfo.blob(), blobInfo.filename())
          this.$store.dispatch('uploadImage', formData)
            .then(data => {
              success(data.link)
            })
            .catch(err => { // eslint-disable-next-line
              failure(`HTTP Error: ${err}`)
            })
        },
        default_link_target: '_blank',
        target_list: [
          { title: 'New page', value: '_blank' },
        ],
      },
    }
  },
  computed: {
    getOptions() {
      if (this.toolbar1) {
        return {
          ...this.options,
          toolbar1: 'formatselect | bold italic strikethrough forecolor backcolor removeformat | link image table | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | fullscreen code',
        }
      }
      if (this.toolbar2) {
        return {
          ...this.options,
          toolbar1: 'bold italic strikethrough forecolor',
        }
      }

      return {
        ...this.options,
        toolbar1: 'bold italic strikethrough forecolor backcolor | link alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | removeformat',
      }
    },
  },
  watch: {
    content: {
      immediate: true,
      handler(newValue) {
        if (newValue !== null) this.$emit('input', newValue)
      },
    },
    value(newValue) {
      this.content = newValue
    },
  },
  methods: {
    resetContent() {
      this.dt1 = this.initial
    },
    initCallBack() {
      this.$refs[this.id].editor.getBody().innerHTML = `${this.value !== null ? this.value : ''}\n`
    },
  },
}
</script>

<style>
 .mce-fullscreen {
   z-index: 9999;
 }
</style>
