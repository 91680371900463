var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-lg-flex justify-content-between"},[_c('div',{staticClass:"col-lg-8 px-0"},[_c('validation-observer',{ref:"courseRules"},[_c('b-form-group',{attrs:{"label":_vm.$t('Event name / title'),"label-for":"eventName"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Event name / title'),"rules":"required|max:80"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"bg-light",attrs:{"id":"eventName","placeholder":_vm.$t('Place some text there...'),"state":errors.length > 0 ? false : null},model:{value:(_vm.course.title),callback:function ($$v) {_vm.$set(_vm.course, "title", $$v)},expression:"course.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":_vm.$t('What is your event about?'),"label-for":"tinymce-editor"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Description'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TinyMCE',{attrs:{"id":"tinymce-editor","placeholder":_vm.$t('Place some text there...')},model:{value:(_vm.course.description),callback:function ($$v) {_vm.$set(_vm.course, "description", $$v)},expression:"course.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4 pl-1"},[_c('b-form-group',{attrs:{"label":_vm.$t('Start date'),"label-for":"startDate"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Start date'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',{staticClass:"bg-light",attrs:{"id":"startDate","nav-button-variant":"muted","placeholder":"DD/MM/YYYY","locale":_vm.$i18n.locale === 'en' ? 'en-UK' : 'nb-NO',"state":errors.length > 0 ? false : null,"date-format-options":{
                  year: 'numeric',
                  day: 'numeric',
                  month: 'numeric',
                }},model:{value:(_vm.course.start_date),callback:function ($$v) {_vm.$set(_vm.course, "start_date", $$v)},expression:"course.start_date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"col-md-4 px-md-1 pl-1"},[_c('b-form-group',{attrs:{"label":_vm.$t('Start time'),"label-for":"startTime"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Start time'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-input-group',{staticClass:" w-100"},[_c('cleave',{staticClass:"form-control bg-light",attrs:{"id":"time-input","raw":false,"options":_vm.options.time,"placeholder":"HH:MM:SS","state":errors.length > 0 ? false : null},model:{value:(_vm.course.start_time),callback:function ($$v) {_vm.$set(_vm.course, "start_time", $$v)},expression:"course.start_time"}}),_c('b-input-group-append',[_c('b-form-timepicker',{staticClass:"bg-light form-control",attrs:{"hide-header":"","button-only":"","no-close-button":"","show-seconds":""},model:{value:(_vm.course.start_time),callback:function ($$v) {_vm.$set(_vm.course, "start_time", $$v)},expression:"course.start_time"}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"col-md-4 px-md-1 pl-1"},[_c('b-form-group',{attrs:{"label":_vm.$t('Estimated duration'),"label-for":"estimatedDuration"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Estimated duration'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-select',{staticClass:"bg-light",attrs:{"placeholder":_vm.$t('Select duration'),"label":"text","reduce":function (duration) { return duration.value; },"options":_vm.estimated_durations,"state":errors.length > 0 ? false : null},model:{value:(_vm.course.estimated_duration),callback:function ($$v) {_vm.$set(_vm.course, "estimated_duration", $$v)},expression:"course.estimated_duration"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4 pl-1"},[_c('b-form-group',{attrs:{"label":_vm.$t('Target'),"label-for":"estimatedDuration"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Target'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-select',{staticClass:"bg-light",attrs:{"label":"name","reduce":function (specialty) { return specialty.id; },"placeholder":_vm.$t('Select specialities'),"multiple":"","state":errors.length > 0 ? false : null,"options":_vm.target_specialties},on:{"input":_vm.SelectAll},model:{value:(_vm.course.target_specialties),callback:function ($$v) {_vm.$set(_vm.course, "target_specialties", $$v)},expression:"course.target_specialties"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"col-md-4 px-md-1 pl-1"},[_c('b-form-group',{attrs:{"label":_vm.$t('Subscription deadline'),"label-for":"subscriptionDeadline"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Subscription deadline'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-datepicker',{staticClass:"bg-light",attrs:{"id":"subscriptionDeadline","nav-button-variant":"muted","placeholder":"DD/MM/YYYY","locale":_vm.$i18n.locale === 'en' ? 'en-UK' : 'nb-NO',"state":errors.length > 0 ? false : null,"date-format-options":{
                  year: 'numeric',
                  day: 'numeric',
                  month: 'numeric',
                }},model:{value:(_vm.course.subscription_deadline),callback:function ($$v) {_vm.$set(_vm.course, "subscription_deadline", $$v)},expression:"course.subscription_deadline"}})]}}])})],1)],1)]),_c('h5',{staticClass:"font-weight-bolder"},[_vm._v(" "+_vm._s(_vm.$t("Thumbnail"))+" ")]),_c('div',{staticClass:"d-flex flex-wrap mb-1"},[_c('div',{staticClass:"\n              file-input\n              d-flex\n              flex-column\n              col-12\n              col-md-4\n              align-items-center\n              justify-content-center\n            "},[_c('img',{attrs:{"src":require("@/assets/images/pages/marketplace/file-drop-image.svg"),"alt":"file"}}),_c('h6',{staticClass:"font-weight-bolder text-center px-1 pt-1"},[_vm._v(" "+_vm._s(_vm.$t("Drop an image here or"))+" "),_c('span',{staticClass:"text-primary cursor-pointer"},[_vm._v(_vm._s(_vm.$t("click")))]),_vm._v(" "+_vm._s(_vm.$t("to upload"))+" ")]),_c('validation-provider',{attrs:{"name":_vm.$t('Images'),"rules":_vm.$route.params.id ? '' : 'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-file',{staticClass:"drop-input",attrs:{"id":"files","state":errors.length > 0 ? false : null},on:{"input":_vm.uploadImg},model:{value:(_vm.image),callback:function ($$v) {_vm.image=$$v},expression:"image"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),(_vm.cropperImg)?_c('div',{staticClass:"col-12 px-0"},[_c('cropper',{attrs:{"img":_vm.cropperImg},on:{"returnImage":_vm.saveCourseImage}})],1):_vm._e(),(_vm.course.image)?_c('div',{staticClass:"col-3 px-0 py-1"},[_c('img',{staticClass:"w-100",attrs:{"src":_vm.course.image,"alt":""}})]):_vm._e(),(_vm.mode === 'webcast')?_c('h5',{staticClass:"font-weight-bolder"},[_vm._v(" "+_vm._s(_vm.$t("Link to video"))+" ")]):_vm._e(),(_vm.mode === 'webcast')?_c('b-form-group',{attrs:{"label":_vm.$t(
            'Video must be uploaded at least 2 days before the webinar starts. (Dropbox, WeTransfer, Google Drive, etc.)'
          ),"label-for":"videoLink"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Video'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-input',{staticClass:"bg-light",attrs:{"id":"videoLink","placeholder":_vm.$t('Place some link there…'),"state":errors.length > 0 ? false : null},model:{value:(_vm.course.embedded),callback:function ($$v) {_vm.$set(_vm.course, "embedded", $$v)},expression:"course.embedded"}})]}}],null,false,331627221)})],1):_c('b-form-group',{attrs:{"label":_vm.$t('Webinar embed code'),"label-for":"webinarEmbedCode"}},[_c('b-form-textarea',{staticClass:"bg-light",attrs:{"id":"webinarEmbedCode","placeholder":_vm.$t('Place some link there…'),"rows":"3"}})],1),(_vm.method !== 'update' && _vm.isABussinesProfile)?_c('b-form-checkbox',{staticClass:"mt-1",model:{value:(_vm.course.generate_post),callback:function ($$v) {_vm.$set(_vm.course, "generate_post", $$v)},expression:"course.generate_post"}},[_vm._v(" "+_vm._s(_vm.$t('Generate the post to the feed'))+" ")]):_vm._e()],1)],1),_c('div',{staticClass:"col-lg-4 text-right nopadding"},[_c('b-button',{staticClass:"mr-1",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.course_methods[_vm.method]()}}},[_vm._v(" "+_vm._s(_vm.method === "create" ? _vm.$t("Publish") : _vm.$t("Save changes"))+" ")]),_c('b-button',{attrs:{"variant":"outline-primary"},on:{"click":_vm.closeCreationForm}},[_vm._v(" "+_vm._s(_vm.method === "create" ? _vm.$t("Cancel") : _vm.$t("Cancel course"))+" ")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }